import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.admin.notice.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-admin-notice-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.admin.notice.list/view.scss */
/* src/app/portal.admin.notice.list/view.scss: no such file or directory */`],
})
export class PortalAdminNoticeListComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public search = {
        dump: 10,
        page: 1,
        text: "",
    };
    public pagination = {
        start: 1,
        end: 1,
    };

    public list = [];
    public async load(page = 1) {
        this.search.page = page;
        const body = this.service.copy(this.search);
        body.category = this.category;
        await this.service.loading.show();
        const { code, data } = await wiz.call("load", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("ERROR");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / this.search.dump) * this.search.dump + 1;
        const tmp = document.createElement("div");
        this.list = rows.map(item => {
            tmp.innerHTML = item.content;
            item.onlyText = tmp.textContent;
            return item;
        });
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }

    public async toggle(item) {
        const { code } = await wiz.call("toggle", { id: item.id });
        if (code !== 200) return await this.service.error("Error!");
        item.enable = !item.enable;
        await this.service.render();
    }

    public async remove(item) {
        const res = await this.service.alert.show({
            title: "공지사항 삭제",
            status: "error",
            message: `해당 공지사항을 삭제하시겠습니까?`,
            action: "삭제",
            actionBtn: "error",
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("remove", { id: item.id });
        if (code !== 200) return await this.service.error("Error!");
        await this.service.success("삭제 성공!");
        await this.load(this.search.page);
    }

    public modal = null;
    public emptyData() {
        return {
            title: "",
            content: "",
            enable: true,
        }
    }
    public async showModal(data) {
        if (data === false) this.modal = null;
        else if (data === true) this.modal = this.emptyData();
        else this.modal = data;
        await this.service.render();
        if (data) await this.init();
    }

    public editor: any;
    public async init() {
        const opt = {
            toolbar: {
                items: 'heading | insertTable | bold italic strikethrough underline | fontColor highlight fontBackgroundColor | bulletedList numberedList todoList | outdent indent | link blockQuote code codeBlock'.split(' '),
                shouldNotGroupWhenFull: true,
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
        };
        this.editor = await ClassicEditor.create(document.querySelector("textarea#content"), opt);
        this.editor.editing.view.change(writer => {
            writer.setStyle('height', '320px', this.editor.editing.view.document.getRoot());
        })

        if (this.modal.content) this.editor.data.set(this.modal.content);
        await this.service.render();
    }

    public async save() {
        const body = this.service.copy(this.modal);
        body.content = this.editor.data.get();
        delete body.category;
        delete body.user_id;
        delete body.created;
        delete body.updated;
        delete body.enable;
        delete body.onlyText;
        const { code } = await wiz.call("save", body);
        if (code !== 200) return await this.service.error("Error!");
        await this.service.success("저장 성공!");
        await this.showModal(false);
        await this.load(this.search.page);
    }
}

export default PortalAdminNoticeListComponent;