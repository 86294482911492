import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.board');
import { OnInit, DoCheck } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-board',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.board/view.scss */
/* src/app/page.board/view.scss: no such file or directory */`],
})
export class PageBoardComponent implements OnInit, DoCheck {
    public O = Object;
    public category = "notice";
    public menus = {
        notice: { title: "공지사항", description: "FataForm의 소식을 알려드려요!" },
    };
    public menuKeys = Object.keys(this.menus);

    constructor(@Inject( Service) public service: Service) {
        this.category = WizRoute.segment.category ? WizRoute.segment.category : "";
        if (!this.category)
            return service.href(`/board/${this.menuKeys[0]}`);
        if (!this.menuKeys.includes(this.category))
            return service.href(`/board/${this.menuKeys[0]}`);
    }

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");
        await this.load();
    }

    public async ngDoCheck() {
        const preCategory = this.category;
        this.category = WizRoute.segment.category ? WizRoute.segment.category : "";

        if (preCategory !== this.category) {
            await this.load();
        }
    }

    public search = {
        dump: 5,
        page: 1,
        text: "",
    };
    public pagination = {
        start: 1,
        end: 1,
    };

    public list = [];
    public async load(page = 1) {
        this.search.page = page;
        const body = this.service.copy(this.search);
        body.category = this.category;
        await this.service.loading.show();
        const { code, data } = await wiz.call("load", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("ERROR");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / this.search.dump) * this.search.dump + 1;
        const tmp = document.createElement("div");
        this.list = rows.map(item => {
            tmp.innerHTML = item.content;
            item.onlyText = tmp.textContent;
            return item;
        });
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }
}

export default PageBoardComponent;