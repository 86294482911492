import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.pagination');
import { OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'wiz-component-pagination',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.pagination/view.scss */
button {
  width: 32px;
  height: 32px;
}`],
})
export class ComponentPaginationComponent implements OnInit, OnChanges {
    @Input() current: any = 1;
    @Input() start: any = 1;
    @Input() end: any = 1;
    @Input() maxlength: any = 10;
    @Output() pageMove = new EventEmitter<number>();

    public disabledClass = "disabled:opacity-50 disabled:cursor-not-allowed";
    public list: Array<number> = [];

    public async ngOnInit() {
        this.Math = Math;
    }

    public async ngOnChanges() {
        this.list = [];
        for (let i = 0; i < this.maxlength; i++) {
            if (this.start + i > this.end) break;
            this.list.push(this.start + i);
        }
    }

    public move(page: number) {
        this.pageMove.emit(page);
    }
}

export default ComponentPaginationComponent;