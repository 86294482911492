import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.admin/view.scss */
/* src/app/page.admin/view.scss: no such file or directory */`],
})
export class PageAdminComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) {
        this.category = WizRoute.segment.category ? WizRoute.segment.category : '';
        if (!['users', 'notice'].includes(this.category))
            return service.href("/admin/users/list");

        this.tab = WizRoute.segment.tab ? WizRoute.segment.tab : '';
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
    }

    public async ngDoCheck() {
        const precategory = this.category;
        this.category = WizRoute.segment.category ? WizRoute.segment.category : '';

        if (precategory !== this.category)
            await this.service.render();

        const pretab = this.tab;
        this.tab = WizRoute.segment.tab ? WizRoute.segment.tab : '';

        if (pretab !== this.tab)
            await this.service.render();
    }
}

export default PageAdminComponent;