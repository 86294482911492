import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.canvas');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";
import Konva from "konva";

@Component({
    selector: 'wiz-component-canvas',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.canvas/view.scss */
/* src/app/component.canvas/view.scss: no such file or directory */`],
})
export class ComponentCanvasComponent implements OnInit {
    @Input() img: Image;
    @Input() onDraw: any;
    @Input() onApplyAfter: any;
    @Input() onClose: any;
    private undoStack: Konva.Shape[][] = []; // 각 레이어의 Undo 스택
    private stage!: Konva.Stage; // Konva 스테이지
    private backgroundLayer!: Konva.Layer; // 배경 레이어
    public layers: { layer: Konva.Layer; name: string }[] = []; // 드로잉 레이어 목록
    public activeLayerIndex: number = 0; // 활성화된 레이어 인덱스

    public lineColor = "#000000";
    public tool = {
        mode: "pen",
        width: 5,
        before: {
            pen: 5,
            eraser: 15,
        },
        default: {
            pen: 5,
            eraser: 15,
        },
        set: async (mode, width = null) => {
            this.tool.before[this.tool.mode] = this.tool.width;
            this.tool.mode = mode;
            if (width) this.tool.width = width;
            else this.tool.width = this.tool.before[mode] || this.tool.default[mode];
            await this.service.render();
        },
    };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        if (!this.img) return;
        await this.getHelpers();
        this.initializeStage();
        this.addLayer(); // 기본 드로잉 레이어 추가
        this.handleResize(); // 리사이즈 이벤트 등록
        this.initBackground();
    }

    public helpers = [];
    public async getHelpers() {
        this.helpers = [];
        const { code, data } = await wiz.call("helpers");
        if (code === 200) {
            this.helpers = data;
        }
        await this.service.render();
    }
    public helperUrl(item) {
        return wiz.url("helper_image") + `?p=${item}`;
    }

    public addHelper(e) {
        const target = e.target;

        const img = new Image();
        img.src = target.src;

        img.onload = () => {
            // const canvas = document.createElement('canvas');
            // canvas.width = img.width;
            // canvas.height = img.height;
            // const ctx = canvas.getContext('2d');
            // ctx.drawImage(img, 0, 0);
            // this.data.before = canvas.toDataURL('image/png');
            this.addLayer(img);
            this.service.render();
        };

        img.onerror = () => {
            console.error('Failed to load image:', target.src);
        };
    }

    public async removeHelper(item) {
        const res = window.confirm("해당 헬퍼를 정말 삭제하시겠습니까?");
        if (!res) return;
        const body = {
            path: item,
        };
        const { code } = await wiz.call("remove_helper", body);
        if (code !== 200) return await this.service.error("헬퍼 삭제 실패!");
        this.getHelpers();
    }

    private initializeStage() {
        const container = document.getElementById("canvas-container");
        // Konva Stage 초기화
        this.stage = new Konva.Stage({
            container: container,
            width: this.img.width,
            height: this.img.height,
            preventDefault: true, // 기본 터치 이벤트 비활성화
        });

        // 배경 레이어 초기화
        this.backgroundLayer = new Konva.Layer();
        this.stage.add(this.backgroundLayer);
        this.service.render();
    }

    public initBackground() {
        const backgroundImage = new Konva.Image({
            image: this.img,
            x: 0,
            y: 0,
            width: this.img.width,
            height: this.img.height,
        });

        // 기존 배경 제거 및 새로운 배경 추가
        this.backgroundLayer.destroyChildren();
        this.backgroundLayer.add(backgroundImage);
        this.backgroundLayer.draw();
    }

    // 새로운 드로잉 레이어 추가
    public addLayer(img = null): void {
        const layer = new Konva.Layer();
        if (img) {
            const _image = new Konva.Image({
                image: img,
                x: 0,
                y: 0,
                width: img.width,
                height: img.height,
            });
            layer.add(_image);
        }
        const layerName = `레이어 ${this.layers.length + 1}`;
        this.stage.add(layer);
        this.layers.push({ layer, name: layerName });
        this.undoStack.push([]); // 새 레이어의 Undo 스택 초기화
        this.activeLayerIndex = this.layers.length - 1;

        this.setActiveLayer(this.activeLayerIndex); // 새 레이어 활성화
    }

    // 드로잉 레이어 삭제
    public deleteLayer(): void {
        const removedLayer = this.layers.splice(this.activeLayerIndex, 1)[0];
        removedLayer.layer.destroy();
        this.activeLayerIndex = Math.max(0, this.activeLayerIndex - 1);

        this.setActiveLayer(this.activeLayerIndex); // 다른 레이어 활성화
        this.service.render();
    }

    // 활성 레이어 변경
    public setActiveLayer(index: number): void {
        if (index < 0 || index >= this.layers.length) return;

        this.activeLayerIndex = index;
        this.updateLayerOpacity(); // 레이어 투명도 업데이트
        this.enableDrawingMode(this.layers[index].layer); // 활성화된 레이어에서만 드로잉 가능
        this.service.render();
    }

    // 레이어 투명도 업데이트
    private updateLayerOpacity(): void {
        this.layers.forEach((layerObj, index) => {
            if (index === this.activeLayerIndex) {
                layerObj.layer.opacity(1); // 활성화된 레이어는 불투명
            } else {
                layerObj.layer.opacity(0.3); // 비활성화된 레이어는 반투명
            }
            layerObj.layer.batchDraw(); // 변경 사항 적용
        });
    }

    private enableDrawingMode(layer: Konva.Layer): void {
        let currentLine: Konva.Line | null = null;

        this.stage.off("pointerdown pointermove pointerup");

        this.stage.on("pointerdown", () => {
            const pos = this.stage.getPointerPosition();
            if (!pos) return;

            // 선 그리기 or 지우기 설정
            currentLine = new Konva.Line({
                stroke: this.lineColor,
                strokeWidth: +this.tool.width,
                lineCap: "round",
                globalCompositeOperation: this.tool.mode === "eraser"
                    ? "destination-out" // 지우기 모드
                    : "source-over", // 일반 그리기 모드
                points: [pos.x, pos.y],
            });

            layer.add(currentLine);
        });

        this.stage.on("pointermove", () => {
            if (!currentLine) return;

            const pos = this.stage.getPointerPosition();
            if (!pos) return;

            const points = currentLine.points();
            points.push(pos.x, pos.y);
            currentLine.points(points);

            layer.batchDraw();
        });

        this.stage.on("pointerup", () => {
            currentLine = null;
        });
    }

    private handleResize(): void {
        window.addEventListener("resize", () => {
            const container = document.getElementById("container");
            if (!container || !this.stage) {
                console.error("Container or stage not found during resize.");
                return;
            }

            // 화면 크기에 따라 Stage 크기 업데이트
            const newWidth = container.clientWidth;
            const newHeight = container.clientHeight;

            this.stage.width(newWidth);
            this.stage.height(newHeight);

            // 배경 및 레이어 크기 재조정
            this.backgroundLayer.children.each((child) => {
                if (child instanceof Konva.Image) {
                    child.width(newWidth);
                    child.height(newHeight);
                }
            });

            this.stage.batchDraw(); // 변경 사항 적용
        });
    }

    public async save() {
        const container = document.getElementById("apply-container");
        const stage = new Konva.Stage({
            container: container,
            width: this.img.width,
            height: this.img.height,
            preventDefault: true, // 기본 터치 이벤트 비활성화
        });
        this.layers.forEach(item => {
            stage.add(item.layer);
        });
        const img = await stage.toImage();
        const res = await this.onDraw(img);

        const orig = new Image();
        orig.src = `${wiz.url("image")}?p=${res.img}`;

        orig.addEventListener("load", () => {
            const lineImg = new Image();
            lineImg.src = `${wiz.url("image")}?p=${res.line}`;
            lineImg.addEventListener("load", () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext('2d');
                canvas.width = this.img.width;
                canvas.height = this.img.height;

                ctx.drawImage(orig, 0, 0);
                ctx.drawImage(lineImg, 0, 0);

                this.onApplyAfter(canvas.toDataURL());
                this.onClose();
            });
        });
    }
}

export default ComponentCanvasComponent;