import '@angular/compiler';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { ComponentFooterComponent } from './component.footer/component.footer.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PageDrawComponent } from './page.draw/page.draw.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalAdminUsersListComponent } from './portal.admin.users.list/portal.admin.users.list.component';
import { PortalAdminNoticeListComponent } from './portal.admin.notice.list/portal.admin.notice.list.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { PageBoardComponent } from './page.board/page.board.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { ComponentCanvasComponent } from './component.canvas/component.canvas.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { ComponentDotBlinkComponent } from './component.dot.blink/component.dot.blink.component';
import { ComponentPaginationComponent } from './component.pagination/component.pagination.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        LayoutAdminComponent,
        ComponentFooterComponent,
        PortalSeasonUiDropdownComponent,
        PageLoginComponent,
        LayoutEmptyComponent,
        PageDrawComponent,
        PageMainComponent,
        PortalSeasonLoadingComponent,
        PortalSeasonLoadingHexaComponent,
        PortalSeasonLoadingSeasonComponent,
        PageMypageComponent,
        PageAdminComponent,
        PortalAdminUsersListComponent,
        PortalAdminNoticeListComponent,
        ComponentNavAdminComponent,
        PortalSeasonTabComponent,
        ComponentQuestionComponent,
        PageBoardComponent,
        PortalSeasonStatusbarComponent,
        ComponentNavUserComponent,
        PortalSeasonPagenationComponent,
        ComponentCanvasComponent,
        PortalSeasonAlertComponent,
        LayoutUserComponent,
        ComponentDotBlinkComponent,
        ComponentPaginationComponent,
        PortalSeasonViewerTreeComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }