import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.draw');
import { OnInit, DoCheck } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-draw',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.draw/view.scss */
.canvas-wrap .canvas {
  min-width: 330px;
}
.canvas-wrap .canvas .no-image {
  background-color: #e3e3e3;
}
.canvas-wrap .canvas img {
  object-fit: contain;
}`],
})
export class PageDrawComponent implements OnInit, DoCheck {
    public O = Object;
    public step = "";
    public menus = {
        planning: "기획 및 각색",
        storyboard: "콘티",
        lineart: "선화",
        coloring: "채색",
        coloring_ai: "채색 AI",
        effects: "효과",
    };
    public menuKeys = Object.keys(this.menus);

    constructor(@Inject( Service) public service: Service) {
        this.step = WizRoute.segment.step ? WizRoute.segment.step : "";
        if (!this.step)
            return service.href(`/draw/${this.menuKeys[0]}`);
        if (!this.menuKeys.includes(this.step))
            return service.href(`/draw/${this.menuKeys[0]}`);
    }

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");
        this.getHistory();
    }

    public async ngDoCheck() {
        const preStep = this.step;
        this.step = WizRoute.segment.step ? WizRoute.segment.step : "";

        if (preStep !== this.step) {
            this.opt.text = "";
            if (this.step === "coloring_ai") this.opt.option = "ColorizeWithRefer(URL)";
            else this.opt.option = "SketchLike";
            await this.service.render();
        }
    }

    public history = [];
    public async getHistory() {
        const { code, data } = await wiz.call("history");
        if (code !== 200) return await this.service.error("Error!");
        this.history = data;
        await this.service.render();
    }
    public imageUrl(item) {
        return wiz.url("image") + `?p=${item}`;
    }
    public async removeImage(item) {
        const { code } = await wiz.call("remove", { path: item });
        if (code !== 200) return await this.service.error("이미지 삭제 시 에러가 발생하였습니다.");
        this.getHistory();
    }

    public currentIndex: number = 0;
    public itemsPerPage: number = 5;

    get paginatedImages(): string[] {
        return this.history.slice(this.currentIndex, this.currentIndex + this.itemsPerPage);
    }

    public nextPage(): void {
        if (this.currentIndex + this.itemsPerPage < this.history.length) {
            this.currentIndex += this.itemsPerPage;
        }
    }

    public previousPage(): void {
        if (this.currentIndex - this.itemsPerPage >= 0) {
            this.currentIndex -= this.itemsPerPage;
        }
    }

    public useImage(e) {
        const target = e.target;

        const img = new Image();
        img.src = target.src;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            this.data.before = canvas.toDataURL('image/png');
            this.service.render();
        };

        img.onerror = () => {
            console.error('Failed to load image:', target.src);
        };
    }

    public data = {
        before: null,
        after: null,
    };

    public src2img(img) {
        const res = new Image();
        res.src = img;
        return res;
    }

    public async upload() {
        const opt = {
            type: "image",
            accept: "image/*",
            width: "4092",
            quality: 1.0,
        };
        const image = await this.service.file.read(opt);
        this.data.before = image;
        await this.service.render();
    }

    public opt = {
        // text: "애니에 나오는 미소녀처럼 모에화해줘",
        text: "",
        option: "SketchLike",
    };
    public async draw() {
        if (!["lineart", "coloring", "coloring_ai"].includes(this.step)) return;
        if (!this.data.before) return;
        const res = window.confirm("draw?");
        if (!res) return;
        const body = new FormData();
        function base64ToBlob(base64, contentType = "image/png") {
            const byteCharacters = atob(base64.split(",")[1]); // Base64의 데이터 부분만 추출
            const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], { type: contentType });
        }

        // Base64 문자열을 File로 변환
        function base64ToFile(base64, fileName = "image.png", contentType = "image/png") {
            const blob = base64ToBlob(base64, contentType);
            return new File([blob], fileName, { type: contentType });
        }
        body.append("step", this.step);
        body.append("image", base64ToFile(this.data.before));
        if (this.step === "coloring_ai") this.opt.option = "ColorizeWithRefer(URL)";
        if (this.step === "coloring") {
            body.append("overlay_image", base64ToBlob(this.overlayImage));
            body.append("option", "coloring");
        }
        else {
            body.append("option", this.opt.option);
        }
        body.append("text", this.opt.text);
        body.append("origin", location.origin);
        await this.service.loading.show();
        const { code, data } = await wiz.call("draw", {}, {
            headers: undefined,
            body,
        });
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("이미지 생성 중 오류가 발생하였습니다.");
        this.data.after = wiz.url(`view?filename=${data}`);
        await this.service.render();
    }

    public async download() {
        if (!this.data.after) return;

        const a = document.createElement("a");
        a.href = this.data.after;
        a.download = `${+new Date()}_${this.opt.option}.png`;
        a.click();
        setTimeout(() => {
            a.remove();
        }, 5000);
    }

    public modal = {
        type: null,
        canvas: null,
        data: null,
        show: async (type, data = null) => {
            this.modal.type = type;
            this.modal.data = data;
            this.modal.canvas = true;
            this.service.render();
        },
        hide: async () => {
            this.modal.type = null;
            this.modal.data = null;
            this.modal.canvas = false;
            await this.getHistory();
        },
    };
    public onClose = this.modal.hide.bind(this);

    public overlayImage = null;
    public onDrawColorize = this._onDrawColorize.bind(this);
    public async _onDrawColorize(img) {
        this.overlayImage = img.src;
        const res = await this.coloring_draw();
        await this.service.render();
        if (!res) return;
        return res;
    }

    public async coloring_draw() {
        if (!this.data.before) return;
        const res = window.confirm("coloring?");
        if (!res) return;
        const body = new FormData();
        function base64ToBlob(base64, contentType = "image/png") {
            const byteCharacters = atob(base64.split(",")[1]); // Base64의 데이터 부분만 추출
            const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], { type: contentType });
        }

        // Base64 문자열을 File로 변환
        function base64ToFile(base64, fileName = "image.png", contentType = "image/png") {
            const blob = base64ToBlob(base64, contentType);
            return new File([blob], fileName, { type: contentType });
        }
        body.append("image", base64ToFile(this.data.before));
        body.append("overlay_image", base64ToBlob(this.overlayImage));
        body.append("text", this.opt.text);
        body.append("origin", location.origin);
        await this.service.loading.show();
        const { code, data } = await wiz.call("coloring", {}, {
            headers: undefined,
            body,
        });
        await this.service.loading.hide();
        console.log(code, data);
        if (code !== 200) return await this.service.error("이미지 채색 중 오류가 발생하였습니다.");
        return data;
    }

    public onApplyAfter = this._onApplyAfter.bind(this);
    public async _onApplyAfter(img) {
        this.data.after = img;
        await this.service.render();
    }
}

export default PageDrawComponent;