import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.admin.users.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-admin-users-list',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.admin.users.list/view.scss */
/* src/app/portal.admin.users.list/view.scss: no such file or directory */`],
})
export class PortalAdminUsersListComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public search = {
        dump: 10,
        page: 1,
        text: "",
    };
    public pagination = {
        start: 1,
        end: 1,
    };

    public list = [];
    public async load(page = 1) {
        this.search.page = page;
        const body = this.service.copy(this.search);
        body.category = this.category;
        await this.service.loading.show();
        const { code, data } = await wiz.call("load", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("ERROR");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / this.search.dump) * this.search.dump + 1;
        this.list = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }

    public async changeRole(user) {
        const role = user.role;
        const body = {
            user_id: user.id,
            role: role === "admin" ? "user" : "admin",
        };
        const res = await this.service.alert.show({
            title: "권한 변경",
            status: "success",
            message: `${user.username} 사용자의 권한을 ${body.role}로 변경하시겠습니까?`,
            action: "변경",
            actionBtn: "success",
            cancel: "취소",
        });
        if (!res) return;
        const { code } = await wiz.call("role_change", body);
        if (code !== 200) return await this.service.error("Error!");
        user.role = body.role;
        await this.load(this.search.page);
        await this.service.success(`${user.username} 사용자의 권한을 ${body.role}로 변경하였습니다.`);
    }

    public async disableUser(user) {

        await this.service.alert.show({
            title: "사용자 비활성화",
            status: "error",
            // message: `${user.username} 사용자의 권한을 ${body.role}로 변경하시겠습니까?`,
            message: `준비 중인 기능입니다.`,
            action: "초기화",
            actionBtn: "error",
            cancel: "취소",
        });
    }

    public async clearPassword(user) {
        await this.service.alert.show({
            title: "패스워드 초기화",
            status: "error",
            // message: `${user.username} 사용자의 권한을 ${body.role}로 변경하시겠습니까?`,
            message: `준비 중인 기능입니다.`,
            action: "초기화",
            actionBtn: "error",
            cancel: "취소",
        });
    }
}

export default PortalAdminUsersListComponent;